import React, {useCallback, useState} from 'react';
import './App.css';
import PointBoard, {CurrentRoundPointClick, CurrentRoundPoints} from "./components/PointBoard";

type PlayerState = {
    name: string,
    points: number[],
    killShotAttempts: number[];
}

type GameState = {
  playerOne: PlayerState;
  playerTwo: PlayerState;
};

const defaultPlayerState : PlayerState = {
    name: 'Player',
    points: [],
    killShotAttempts: []
};

const defaultGameState : GameState = {
    playerOne: { ...defaultPlayerState, name: 'Player One' },
    playerTwo: { ...defaultPlayerState, name: 'Player Two' }
}

const newRoundState : CurrentRoundPoints = {
    selectedPoints: undefined,
    usingKillShot: false,
    canUseKillShot: true
};

const maxRounds = 10;

function App() {
    const [editingPlayers, setEditingPlayers] = useState(false);
  const [gameState, setGameState] = useState<GameState>(defaultGameState);
  const [playerOneCurrentRound, setPlayerOneCurrentRound] = useState<CurrentRoundPoints>(newRoundState);
  const [playerTwoCurrentRound, setPlayerTwoCurrentRound] = useState<CurrentRoundPoints>(newRoundState);

  const round = gameState.playerOne.points.length + 1;
  const gameOver = round > maxRounds;
  const roundInProgress = playerOneCurrentRound.selectedPoints === undefined
      || playerTwoCurrentRound.selectedPoints === undefined;

  const playerOneTotal = gameState.playerOne.points.reduce((prev, current) => prev + current, 0);
  const playerTwoTotal = gameState.playerTwo.points.reduce((prev, current) => prev + current, 0);
  const getWinner = () => {
      if (!gameOver) return undefined;
      if (playerOneTotal > playerTwoTotal) return `${gameState.playerOne.name} Wins!`;
      if (playerTwoTotal > playerOneTotal) return `${gameState.playerTwo.name} Wins!`;
      return 'It\'s a Tie!';
  };

  const handleCompleteRound = () => {
      if (roundInProgress) return;

      const newGameState = {
          playerOne: {
              ...gameState.playerOne,
              points: [...gameState.playerOne.points, playerOneCurrentRound.selectedPoints!],
              killShotAttempts: playerOneCurrentRound.usingKillShot
                  ? [...gameState.playerOne.killShotAttempts, round]
                  : gameState.playerOne.killShotAttempts
          },
          playerTwo: {
              ...gameState.playerTwo,
              points: [...gameState.playerTwo.points, playerTwoCurrentRound.selectedPoints!],
              killShotAttempts: playerTwoCurrentRound.usingKillShot
                  ? [...gameState.playerTwo.killShotAttempts, round]
                  : gameState.playerTwo.killShotAttempts
          }
      };

      setGameState(newGameState);

      const getNewRoundState : ((playerState: PlayerState) => CurrentRoundPoints) = (playerState: PlayerState) => {
          const killShotsUsed = playerState.killShotAttempts.length;
          if (killShotsUsed < 2) return newRoundState;
          if (killShotsUsed >= 3) return { ...newRoundState, canUseKillShot: false };

          let droppedKillShot = false;

          playerState.killShotAttempts.forEach((attempt) => {
              if (playerState.points[attempt - 1] === 0) {
                  droppedKillShot = true;
              }
          });

          return { ...newRoundState, canUseKillShot: droppedKillShot };
      };

      setPlayerOneCurrentRound(getNewRoundState(newGameState.playerOne));
      setPlayerTwoCurrentRound(getNewRoundState(newGameState.playerTwo));
  };

  const newGame = useCallback(() => {
      const newGameState = {
          playerOne: {
              ...defaultPlayerState,
              name: gameState.playerOne.name
          },
          playerTwo: {
              ...defaultPlayerState,
              name: gameState.playerTwo.name
          }
      };

      setGameState(newGameState);
      setPlayerOneCurrentRound(newRoundState);
      setPlayerTwoCurrentRound(newRoundState);
  }, [gameState.playerOne.name, gameState.playerTwo.name]);

  const handlePlayerOnePointsClick = (pointsClick: CurrentRoundPointClick) => {
      setPlayerOneCurrentRound((prev) => ({
          ...prev,
          ...pointsClick
      }));
  };

    const handlePlayerTwoPointsClick = (pointsClick: CurrentRoundPointClick) => {
        setPlayerTwoCurrentRound((prev) => ({
            ...prev,
            ...pointsClick
        }));
    };

    const EditPlayers = () => {
        const [playerNames, setPlayerNames] = useState({
            playerOne: gameState.playerOne.name,
            playerTwo: gameState.playerTwo.name
        });

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setPlayerNames((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }));
        };

        const handleFormSubmit = (e: React.FormEvent) => {
            e.preventDefault();

            setGameState((prev) => ({
                ...prev,
                playerOne: {
                    ...prev.playerOne,
                    name: playerNames.playerOne
                },
                playerTwo: {
                    ...prev.playerTwo,
                    name: playerNames.playerTwo
                }
            }));

            setEditingPlayers(false);
        };

        const formEnabled = playerNames.playerOne.trim().length > 0 && playerNames.playerTwo.trim().length > 0;

        return (
            <>
                <h3>Edit Players</h3>
                <form onSubmit={handleFormSubmit}>
                    <div className="formRow">
                        <input type="text"
                               name="playerOne"
                               value={playerNames.playerOne}
                               onChange={handleChange}
                               placeholder="Player One Name" />
                    </div>
                    <div className="formRow">
                        <input type="text"
                               name="playerTwo"
                               value={playerNames.playerTwo}
                               onChange={handleChange}
                               placeholder="Player Two Name" />
                    </div>
                    <div className="formRow">
                        <button disabled={!formEnabled} type="submit">Save</button>
                    </div>
                </form>
            </>
        )
    };

    const Game = () => (
        <>
            { !gameOver && (
                <>
                    <p className="roundDisplay">Round {round}</p>
                    <p>{gameState.playerOne.name}</p>
                    <PointBoard currentRoundPoints={playerOneCurrentRound}
                                onClick={handlePlayerOnePointsClick} />
                    <p>{gameState.playerTwo.name}</p>
                    <PointBoard currentRoundPoints={playerTwoCurrentRound}
                                onClick={handlePlayerTwoPointsClick} />
                    <button className="completeButton" disabled={roundInProgress} onClick={handleCompleteRound}>Complete Round</button>
                </>
            )}
            { gameOver && <h3 className="winnerDisplay">{getWinner()}</h3>}
            <table>
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>{gameState.playerOne.name}</th>
                    <th>{gameState.playerTwo.name}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Total</td>
                    <td>{playerOneTotal}</td>
                    <td>{playerTwoTotal}</td>
                </tr>
                { gameState.playerOne.points.map((playerOneValue, index) => <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                        {playerOneValue}
                        {gameState.playerOne.killShotAttempts.find((value) => value - 1 === index) && (<> K</>)}
                    </td>
                    <td>
                        {gameState.playerTwo.points[index]}
                        {gameState.playerTwo.killShotAttempts.find((value) => value - 1 === index) && (<> K</>)}
                    </td>
                </tr>) }
                </tbody>
            </table>
        </>
    );

  return (
    <div className="App">
        <header className="App-header">
            <h2>Axes!</h2>
            <button onClick={() => setEditingPlayers(true)}>Edit Players</button>
            <button onClick={newGame}>New Game</button>
        </header>
      <main>
          <div className="container">
              { editingPlayers && <EditPlayers /> }
              { !editingPlayers && <Game /> }
          </div>
      </main>
    </div>
  );
}

export default App;
