import styles from '../styles/PointsBoard.module.css';

const possiblePoints = [0, 1, 2, 3, 4, 5, 6];

export type CurrentRoundPoints = {
    selectedPoints: number | undefined;
    usingKillShot: boolean;
    canUseKillShot: boolean;
};

export type CurrentRoundPointClick = Pick<CurrentRoundPoints, 'selectedPoints' | 'usingKillShot'>;

type PointBoardProps = {
    currentRoundPoints: CurrentRoundPoints;
    onClick: (points: CurrentRoundPointClick) => void;
};

const PointBoard = ({ currentRoundPoints, onClick } : PointBoardProps) => {
    const { selectedPoints, usingKillShot, canUseKillShot } = currentRoundPoints;

    const handleRegularClick = (points: number) => {
       const payload: CurrentRoundPointClick = {
           selectedPoints: points,
           usingKillShot: false
       };

       onClick(payload);
    };

    if (usingKillShot) {
        return (
            <div className={styles.pointsBoard}>
                <button onClick={() => onClick({ selectedPoints: 0, usingKillShot: true })}
                        className={selectedPoints === 0 ? styles.selectedPoint : undefined}>
                    Drop
                </button>
                <button onClick={() => onClick({ selectedPoints: 8, usingKillShot: true })}
                        className={selectedPoints === 8 ? styles.selectedPoint : undefined}>
                    Kill
                </button>
                <button onClick={() => onClick({ selectedPoints: undefined, usingKillShot: false })}>
                    Cancel
                </button>
            </div>
        );
    }

    return (
        <div className={styles.pointsBoard}>
            { possiblePoints.map((points) => (
                <button key={points}
                        onClick={() => handleRegularClick(points)}
                        className={selectedPoints === points ? styles.selectedPoint : undefined}>
                    {points}
                </button>
            ))}
            { canUseKillShot && (
                <button onClick={() => onClick({ selectedPoints: undefined, usingKillShot: true })}>K</button>
            )}
        </div>
    );
};

export default PointBoard;